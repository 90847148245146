<template>
    <v-app>
        <v-main>    
            <v-btn @click="revisar()">Revisar </v-btn>
        </v-main>
    </v-app>
</template>
<script>
export default {
    data(){
        return {
            responses: [],
        }
    },
    created(){
        //this.get_archivos_parse();
    },
    methods: {
        async get_archivos_parse(){
            try {
                /*
                let buscar_login = JSON.stringify({
                usuario: this.datosInicio.usuario,
                clave: this.datosInicio.clave,
                });

                buscar_login = encodeURIComponent(buscar_login);
                */

                //const count = encodeURIComponent('1');
                const limit = encodeURIComponent('12914');
                const response = await this.$store.dispatch('llamado_get',{
                    url: "https://siett.educandote.co/parse/classes/IMAGENESV2_V1_SIETT_DOCUMENT_V3_2?limit="+limit,
                    tipo_header: "parse"
                });



                console.log(response.data.results);

                this.agrupar(response.data.results);
            } catch (error) {
                console.log(error);
            }
        },
        async agrupar(data){


            try {


                console.log(data);

                let start = 0;
                let end = 0;
                for (let index = 0; index < 52; index++) {
                    
                    end = ((index+1)*250);

                    let data_to_send = data.slice(start,end);
                    
                    start = end;
                    console.log(data_to_send);
                    await this.indexar_grupo(index,data_to_send);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async indexar_grupo(group,data){
            
            let body = "";
            for (let index = 0; index < data.length; index++) {
                body = body+`${data[index].body}\n`
            }

            console.log(body);
            let imagenes;
            try {
                
                
                imagenes = await this.$store.dispatch('llamado_post',{
                    url:`https://siett.educandote.co/types/collections/${'IMAGENES_V1_SIETT_DOCUMENT_V3_2'}/documents/import?action=create`,
                    body: body,
                    tipo_header: "indexador"
                });  
                console.log(imagenes);  
    

                this.responses.push({group: group, data: data, response: imagenes, status: true, body: body });

            } catch (error) {
                console.log(error);
                this.responses.push({group: group, data: data, response: error, status: false,body: body });
            }
        },
        revisar(){
            console.log(this.responses);
        }
    }
}
</script>